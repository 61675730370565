<template>
  <section class="innerpage-wrap">
    <div class="container"  ref="saveBilling">
      <div class="split-title">
        <h1 class="brand-color">Billing Information</h1>
      </div>
      <div class="enrollmentForm card-block">
        <div class="form-group mb-2">
          <h3>Payment Type</h3>
          <div class="form-check form-check-inline mt-3" checked="checked" v-if="computedPaymentType == 'cc'">
            <input class="form-check-input" type="radio"  id="inlineRadio1" v-model="selected_type" value="cc" name="inlineRadioOptions" checked="checked" @click="maintainPaymentTypeCC">
            <label class="form-check-label" for="inlineRadio1">Credit Card</label>
          </div>
          <div class="form-check form-check-inline mt-3" v-if="paymentType === 'eft' ||  showEft === true">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="eft" checked="checked" v-model="selected_type" @click="maintainPaymentTypeEFT">
            <label class="form-check-label" for="inlineRadio1">EFT</label>
          </div>
          <div class="form-check form-check-inline mt-3" v-if="paymentType === 'elist' || paymentType === 'list'">
            <input class="form-check-input" type="radio"
                   v-if="paymentType === 'elist'"
                   name="inlineRadioOptions"
                   id="inlineRadio2"
                   value="elist"
                   checked="checked"
                   v-model="selected_type" @click="maintainPaymentTypeELIST">
            <input class="form-check-input"
                   v-if="paymentType === 'list'"
                   type="radio"
                   name="inlineRadioOptions"
                   id="inlineRadio2"
                   value="list"
                   checked="checked"
                   v-model="selected_type" @click="maintainPaymentTypeLIST">
            <label v-if="paymentType === 'list'" class="form-check-label" for="inlineRadio2">LIST</label>
            <label v-if="paymentType === 'elist'" class="form-check-label" for="inlineRadio2">EFT LIST</label>
          </div>
          <div class="form-check form-check-inline mt-3" v-if="paymentType === 'stmt'">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="stmt" checked="checked" v-model="selected_type" @click="maintainPaymentTypeSTMT">
            <label class="form-check-label" for="inlineRadio3">STMT</label>
          </div>
        </div>
        <div class="form-group mb-4"  v-if="selected_type == 'eft'">
          <h3 class="mb-3">Agreement, Application and Authorization</h3>
          <div class="notes" >
            <h6><strong>EFT/ACH</strong> (You agree that payments will  be made by Electronic Fund Transfer (EFT) from your bank account.)</h6>
            <p><strong>Your Initial Payment:</strong> The initial payment  will charge your bank account for first month's premium fee and one time emrollment fee. This will occur anytime from the date of enrollment to 5 days after enrollment (we may require you to submit a legible voided check).
              <br><br>
              The will appear on statement as ( <strong>{{merchantInfo.text}}</strong> ).
              <br><br>
              <strong>Your Monthly Payment:</strong> The monthly payment will charge your bank account for the monthly premium. The charge date is based on the effective date of
              your primary policy. This will normally occur between the 1st and 6th day of the month for coverage effective on the 15th of the month and between the 21st & 26th of the month
              prior for coverage beginning on the first of next month. (There is NO monthly charge for EFT).
              <br><br>
              <strong>Note:</strong> You understand this authority is to remain in full force and effect until the company has received written notification from you of its termination in such time and suchj manner as to afford the company and depositor a reasonable
              opportunity to act on it. You have the right to stop payment of debit entry (deduction) by notification three business days or more before this payment is scheduled to be made. There is a $30
              insufficient funds free.
            </p>
          </div>
          <div>
            <form class="" action="index.html" method="post">
              <div class="form-group pt-4 row">
                <h3 class="mb-3">Bank Account/Routing Details</h3>
                <div>
                  <label><strong>Billing Date:</strong></label>
                  <div class="billing-date">
                      <b-form-radio-group
                          v-model="formFields.bill_date.value"
                          :options="billingDateOptions"
                          name="radio-inline" />
                  </div>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12  mb-1">
                  <label><strong>Payment Type:</strong> </label>
                  <span class="paymentType">{{paymentType}}</span>
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12" v-if="bankData.length > 0"  >
                    <div class="row">
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12">
                        <input type="radio"
                               :value="true"
                               checked="checked"
                               @click="checkedExistingBankDetails(bankData[0].bank_id)"
                               v-model="selectedExisitngBank">
                        <span><label><strong class="bank-details-radio-align">Existing Bank Details</strong></label></span>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12">
                        <input type="radio"
                               name="radio"
                               :value="false"
                               :checked="selected===false"
                               @click="checkedNewBankDetails()">
                        <span> <label><strong class="bank-details-radio-align">New Bank Details</strong></label></span>
                      </div>
                    </div>
                    <div class="row" v-if="selected">
                      <div class="col-xxl-9 col-xl-9 col-lg-8 col-md-12 col-12">
                        <div v-for="(bank,index) in bankData" v-bind:key="index" >
                          <div class="client_email" v-if="selected && selected_type == 'eft'">
                            <!--                      <div class="ce_list eb_radio"><input type="radio" name="radio" :value="bank.bank_id" :checked="selected===bank.bank_id" :id="bank.bank_id" @click="uncheck(bank.bank_id)"></div>-->
                            <div class="row mt-4">
                              <div class="ce_list eb_radio col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12 ">
                                <span class="radio-btn-align"><input type="radio"
                                             name="radio"
                                             :value="bank.bank_id"
                                             :checked="selected===bank.bank_id"
                                             @click="checkedExistingBankDetails(bank.bank_id)"
                                             :id="bank.bank_id" /></span>
                              </div>
                                <div class="ce_list eb_radio col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-12 g-0 bank-existing-list-container">
                                  <div class="row existing-form-inner-wrapper">
                                    <div class="ce_list col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4"><label>Account Holder Name</label><input class="custom-input" type="text" readonly="readonly" :value="bank.account_name"></div>
                                    <div class="ce_list col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4"><label>Bank</label><input class="custom-input" type="text" readonly="readonly" :value="bank.bank_name"></div>
                                    <div class="ce_list col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4"><label>Account Number</label><input class="custom-input" type="text" readonly="readonly" :value="bank.account_number"></div>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row"  v-if="!selected && selected_type == 'eft'">
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.account_name.required == true">
                    <label>Account Holder Name <span class="required">*</span></label>
                    <input type="text"
                           class="custom-input"
                           placeholder="Enter Account Holder Name"
                           @input="$v.formFields.account_name.value.$touch(); removeError();"
                           :class="{'form-control is-invalid':$v.formFields.account_name.value.$error || formErrors['account_name_message']}"
                           name="" id=""
                           v-model="formFields.account_name.value"
                           value="" :disabled="isDisabled">
                    <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.account_name.value.required && $v.formFields.account_name.value.$error">
                      Field is required
                    </div>
                    <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.account_name.value.required && $v.formFields.account_name.value.$error">
                      Invalid account holder name
                    </div>
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['account_name_message']}}</div>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.account_number.required == true">
                    <label>Account Number <span class="required">*</span></label>
                    <input type="number"
                           class="custom-input"
                           placeholder="Enter Account Number"
                           name="" id=""
                           @input="$v.formFields.account_number.value.$touch(); removeError();"
                           :class="{'form-control is-invalid':$v.formFields.account_number.value.$error || formErrors['account_number_message'] }"
                           v-model="formFields.account_number.value"
                           value="" :disabled="isDisabled">
                    <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.account_number.value.required && $v.formFields.account_number.value.$error">
                      Field is required
                    </div>
                    <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.account_number.value.required && $v.formFields.account_number.value.$error">
                      Account Number must be of 4 to 17 digits.
                    </div>
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['account_number_message']}}</div>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.routing_number.required == true">
                    <label>Routing Number <span class="required">*</span></label>
                    <input type="text"
                           class="custom-input"
                           placeholder="Enter Routing Number"
                           :class="{'form-control is-invalid':$v.formFields.routing_number.value.$error || formErrors['routing_number_message'] || routingErrorMessage}"
                           @input="$v.formFields.routing_number.value.$touch();removeError();"
                           maxlength="9"
                           name=""
                           id=""
                           v-model="formFields.routing_number.value" v-on:blur="validRouting()" value="" max-length=9 :disabled="isDisabled">
                    <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.routing_number.value.required && $v.formFields.routing_number.value.$error">
                      Field is required
                    </div>
                    <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.routing_number.value.required && $v.formFields.routing_number.value.$invalid">
                      Routing number must be of 9 digits
                    </div>
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['routing_number_message']}}</div>
                    <div class="error_message" v-if="routingErrorMessage">{{routingErrorMessage}}</div>
                  </div>
                </div>
              </div>

              <div class="form-group row" v-if="!selected && selected_type == 'eft'">
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.bank_name.required == true">
                  <label>Bank Name <span class="required">*</span></label>
                  <input type="text"
                         class="custom-input"
                         placeholder="Enter Bank Name"
                         name=""
                         id=""
                         :class="{'form-control is-invalid':$v.formFields.bank_name.value.$error || formErrors['bank_name_message']}"
                         @input="$v.formFields.bank_name.value.$touch();removeError();"
                         v-model="formFields.bank_name.value"
                         value=""
                         :disabled="isDisabled">
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.bank_name.value.required && $v.formFields.bank_name.value.$error">
                    Field is required
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['bank_name_message']}}</div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.account_type.required == true">
                  <label>Account Type <span class="required">*</span></label>
                  <b-form-select name=""
                          class="custom-input"
                          id=""
                          :options="accountTypeOptions"
                          @input="$v.formFields.account_type.value.$touch(); removeError();"
                          :class="{'form-control is-invalid':$v.formFields.account_type.value.$error || formErrors['account_type_message']}"
                          v-model="formFields.account_type.value"
                          :disabled="isDisabled">
                  </b-form-select>
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.account_type.value.required && $v.formFields.account_type.value.$error">
                    Field is required
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['account_type_message']}}</div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.account_holder_type.required == true">
                  <label>Acount Holder Type <span class="required">*</span></label>
                  <b-form-select name=""
                          class="custom-input"
                          @input="$v.formFields.account_holder_type.value.$touch(); removeError();"
                          :options="accountHolderTypeOptions"
                          :class="{'form-control is-invalid':$v.formFields.account_holder_type.value.$error || formErrors['account_holder_type_message']}"
                          id=""
                          v-model="formFields.account_holder_type.value"
                          :disabled="isDisabled">
                  </b-form-select>
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.account_holder_type.value.required && $v.formFields.account_holder_type.value.$error">
                    Field is required
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['account_holder_type_message']}}</div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="form-group mb-4" v-if="paymentType == 'stmt'">
          <div class="notes">
            <h6><strong>STMT</strong><span class="guide">( You have selected the "Statement" mode for payments on this policy. )</span></h6>
          </div>
        </div>
        <div class="form-group mb-4" v-if="paymentType == 'list'">
          <div class="notes">
            <h6><strong>LIST</strong><span class="guide">( You agree that payments will be made by List Bill. )</span></h6>
          </div>
        </div>
        <div class="form-group mb-4" v-if="paymentType == 'elist'">
          <div class="notes">
            <h6><strong>EFT LIST</strong><span class="guide">( You agree that payments will be made by eft List Bill. )</span></h6>
          </div>
        </div>

        <div class="form-group mb-4" v-if="selected_type === 'cc'">
          <h3 class="mb-3">Agreement, Application and Authorization</h3>
          <div>
            <form class="" action="index.html" method="post">
              <div class="form-group row">
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="cardData.length > 0" style="padding-bottom: 20px !important;">
                  <label>Existing Card Details</label>
                  <div v-for="(card,index) in cardData" v-bind:key="index">
                    <div class="client_email">
                      <div class="ce_list eb_radio credit_card"><input type="radio" name="radio" :value="card.card_id" :checked="selected_card===card.card_id" :id="card.card_id" @click="uncheckCard(card.card_id)"></div>
                      <div class="ce_list ncard"><label for="">Name on Card</label>
                        <input class="input-box" type="text"
                               readonly="readonly"
                               style="font-size: 13px !important;"
                               :value="card.name_on_cc">
                      </div>
                      <div class="ce_list cc-number">
                        <label for="">Card Number</label>
                        <input class="input-box"
                               type="text"
                               readonly="readonly"
                               style="font-size: 13px !important;"
                               :value="card.card_number">

                        <div class="error_message" v-if="formErrorFlag == true">{{formErrors['card_number_e_message']}}</div>
                        <div class="credit_card_logo">
                          <img src="/images/cc_logo.png" alt="">
                        </div>
                      </div>
                      <div class="ce_list"><label for="">Expiry Date</label>
                        <input class="input-box"
                               type="text" readonly="readonly"
                               style="font-size: 13px !important;"
                               :value="card.expiration_month">
                        <div class="error_message" v-if="formErrorFlag == true">{{formErrors['expiry_date_e_message']}}</div>
                      </div>
                      <div class="ce_list scode">
                        <label for="">Security Code</label>
                        <input class="input-box"
                               type="text"
                               readonly="readonly"
                               style="font-size: 13px !important;"
                               :value="card.security_code">
                        <div class="error_message" v-if="formErrorFlag == true">{{formErrors['security_code_e_message']}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <label>New Card Details</label>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.first_name_on_cc.required == true">
                  <label>First Name on Card<span class="required">*</span></label>
                  <input type="text"
                         class="custom-input"
                         placeholder="Enter First Name on Card"
                         name=""
                         id=""
                         @input="$v.formFields.first_name_on_cc.value.$touch(); removeError();"
                         :class="{'form-control is-invalid':$v.formFields.first_name_on_cc.value.$error || formErrors['first_name_on_cc_message'] }"
                         v-model="formFields.first_name_on_cc.value"
                         :disabled="isDisabled_card">
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.first_name_on_cc.value.required && $v.formFields.first_name_on_cc.value.$error">
                    Field is required
                  </div>
                  <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.first_name_on_cc.value.required && $v.formFields.first_name_on_cc.value.$error">
                    Please enter valid first name
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['first_name_on_cc_message']}}</div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.last_name_on_cc.required == true">
                  <label>Last Name on Card <span class="required">*</span></label>
                  <input type="text"
                         class="custom-input"
                         placeholder="Enter Last Name on Card"
                         name=""
                         id=""
                         @input="$v.formFields.last_name_on_cc.value.$touch(); removeError();"
                         v-bind:class="{'form-control is-invalid':$v.formFields.last_name_on_cc.value.$error } || formErrors['last_name_on_cc_message']"
                         v-model="formFields.last_name_on_cc.value"
                         :disabled="isDisabled_card">
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.last_name_on_cc.value.required && $v.formFields.last_name_on_cc.value.$error">
                    Field is required
                  </div>
                  <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.last_name_on_cc.value.required && $v.formFields.last_name_on_cc.value.$error">
                    Please enter valid last name
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['last_name_on_cc_message']}}</div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.card_number.required == true">
                  <label>Card Number <span class="required">*</span></label>
                  <input type="text"
                         class="custom-input"
                         placeholder="Enter Card Number"
                         name=""
                         id=""
                         @input="$v.formFields.card_number.value.$touch(); removeError();"
                         v-bind:class="{'form-control is-invalid':$v.formFields.card_number.value.$error || formErrors['card_number_message'] }"
                         v-model="formFields.card_number.value"
                         :disabled="isDisabled_card">
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.card_number.value.required && $v.formFields.card_number.value.$error">
                    Field is required
                  </div>
                  <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.card_number.value.required && $v.formFields.card_number.value.$error">
                    Card number must be Min-13 and Max-25
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['card_number_message']}}</div>
                </div>
                <div class="credit_card_logo">
                  <img src="/images/cc_logo.png" alt="">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.security_code.required == true">
                  <label>Security Code<span class="required">*</span></label>
                  <input type="number"
                         class="custom-input"
                         placeholder="Enter Security code"
                         name=""
                         id=""
                         :class="{'form-control is-invalid':$v.formFields.security_code.value.$error || formErrors['security_code_message'] }"
                         @input="$v.formFields.security_code.value.$touch();removeError();"
                         v-model="formFields.security_code.value"
                         :disabled="isDisabled_card">
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.security_code.value.required && $v.formFields.security_code.value.$error">
                    Field is required
                  </div>
                  <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.security_code.value.required && $v.formFields.security_code.value.$error">
                    Security code must of 3 or 4 digits
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['security_code_message']}}</div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.expiration_month.required == true" >
                  <label>Expiration Month<span class="required">*</span></label>
                  <b-form-select name=""
                          class="custom-input"
                          id=""
                          :class="{'form-control is-invalid':$v.formFields.expiration_month.value.$error || formErrors['expiration_month_message']}"
                          @input="$v.formFields.expiration_month.value.$touch();removeError();"
                          v-model="formFields.expiration_month.value"
                          :disabled="isDisabled_card">
                    <option value="null">Select Expiration Month</option>
                    <option v-for="month in expirationMonths" :value="month" :key="month">{{month}}</option>
                  </b-form-select>
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.expiration_month.value.required && $v.formFields.expiration_month.value.$error">
                    Field is required
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['expiration_month_message']}}</div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.expiration_year.required == true">
                  <label>Expiration Year <span class="required">*</span></label>
                  <b-form-select name=""
                          class="custom-input"
                          id=""
                          :class="{'form-control is-invalid':$v.formFields.expiration_year.value.$error || formErrors['expiration_year_message']}"
                          @input="$v.formFields.expiration_year.value.$touch();removeError();"
                          v-model="formFields.expiration_year.value"
                          :disabled="isDisabled_card">
                    <option value="null">Select Expiration Year</option>
                    <option v-for="year in expirationYears" :value="year" :key="year">{{year}}</option>
                  </b-form-select>
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.expiration_year.value.required && $v.formFields.expiration_year.value.$error">
                    Field is required
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['expiration_year_message']}}</div>
                </div>
              </div>
            </form>
          </div>


          <div>
            <label>Billing Details</label>
            <form class="" action="index.html" method="post">
              <div class="form-group row">
                <div class="form-group mb-2">
                  <div class="form-check form-check-inline mt-3">
                    <input class="form-check-input"
                           type="checkbox"
                           id="inlineRadio1"
                           v-model="choose_pa"
                           @click="populatePhysicalAddress"
                           :disabled="isDisabled_card">
                    <label class="form-check-label" for="inlineRadio1">Choose physical address</label>
                  </div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.address1.required == true" >
                  <label>Street Address 1<span class="required">*</span></label>
                  <input type="text"
                         class="custom-input"
                         placeholder="Enter Street Address"
                         :class="{'form-control is-invalid':$v.formFields.address1.value.$error || formErrors['address1_message']}"
                         @input="$v.formFields.address1.value.$touch();removeError();"
                         name="" id="" v-model="formFields.address1.value"
                         :readonly="formFields.address1.read_only"
                         :disabled="isDisabled_card">
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.address1.value.required && $v.formFields.address1.value.$error">
                    Field is required
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['address1_message']}}</div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.address2.required == true" >
                  <label>Street Address 2 <span class="required">*</span></label>
                  <input type="text"
                         class="custom-input"
                         placeholder="Enter Street Address 2"
                         name="" id=""
                         :class="{'form-control is-invalid':$v.formFields.address2.value.$error || formErrors['address2_message']}"
                         @input="$v.formFields.address2.value.$touch();removeError();"
                         v-model="formFields.address2.value"
                         :readonly="formFields.address2.read_only"
                         :disabled="isDisabled_card">
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.address2.value.required && $v.formFields.address2.value.$error">
                    Field is required
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['address2_message']}}</div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.city.required == true" >
                  <label>City <span class="required">*</span></label>
                  <input type="text"
                         class="custom-input"
                         placeholder="Enter city name"
                         name=""
                         id=""
                         :class="{'form-control is-invalid':$v.formFields.city.value.$error || formErrors['city_message']}"
                         @input="$v.formFields.city.value.$touch();removeError();"
                         v-model="formFields.city.value"
                         :readonly="formFields.city.read_only"
                         :disabled="isDisabled_card">
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.city.value.required && $v.formFields.city.value.$error">
                    Field is required
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['city_message']}}</div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.zip.required == true">
                  <label>Zip<span class="required">*</span></label>
                  <input type="number"
                         class="custom-input"
                         placeholder="Enter Zip code"
                         name=""
                         id=""
                         @input="$v.formFields.zip.value.$touch(); removeError();"
                         :class="{'form-control is-invalid':$v.formFields.zip.value.$error || formErrors['zip_message'] }"
                         v-model="formFields.zip.value"
                         :readonly="formFields.zip.read_only"
                         :disabled="isDisabled_card"
                         oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                         maxlength="5">
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.zip.value.required && $v.formFields.zip.value.$error">
                    Field is required
                  </div>
                  <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.zip.value.required && $v.formFields.zip.value.$error">
                    Invalid Zip code
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['zip_message']}}</div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.state.required == true" >
                  <label>State<span class="required">*</span></label>
                  <b-form-select name=""
                          class="custom-input"
                          id=""
                          @input="$v.formFields.state.value.$touch(); removeError();"
                          :class="{'form-control is-invalid':$v.formFields.state.value.$error || formErrors['state_message'] }"
                          v-model="formFields.state.value"
                          :disabled="formFields.state.read_only || isDisabled_card">
                    <option value="null">Select State</option>
                    <option v-for="state in states" :value="state.abbrev" :key="state.abbrev">{{state.name}} ({{state.abbrev}})</option>
                  </b-form-select>
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.state.value.required && $v.formFields.state.value.$error">
                    Field is required
                  </div>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['state_message']}}</div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <b-modal v-bind:hide-footer="true" id="code-send" centered size="md" >
        <template #modal-header>
          <div class="popUp-header" style="border-bottom: 0px !important">
            <div  v-if="saveSuccess">
              <h4 style="color: green"><b>Information Saved!!!</b> Check your email to continue</h4>
            </div>
            <div v-else>
              <h4>Please enter the alternate email you want to send Enrollment code to?</h4>
            </div>
            <b-button variant="close-btn" @click="$bvModal.hide('code-send')">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <b-container fluid>
          <div v-if="saveSuccess==false" class="input_box client_email">
            <input type="text"
                   class="custom-input"
                   :class="{'form-control is-invalid': email && !isEmailValid || isEmailNull}"
                   @input="emailValid(); removeError();"
                   v-model="email" value="" />
            <div class="form-group__message  ml-3 mt-2" v-if="email && !isEmailValid || isEmailNull">
              Enter valid email address
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['email_message']}}</div>
          </div>
          <div class="popUp-details text-center">
            <div v-if="saveSuccess" class="action-btn text-center mt-4">
              <b-button style="width:100%;"  class="button-brand mx-1" @click="$bvModal.hide('code-send')" data-dismiss="modal">Done</b-button>
            </div>
            <div v-else class="action-btn text-center mt-4">
              <b-button style="width:40%;"  class="dark-btn mx-1" @click="$bvModal.hide('code-send')">Back</b-button>
              <b-button style="width:40%;"  class="button-brand mx-1" @click="saveAndContinueEnrollment" data-dismiss="modal">Send Code</b-button>
            </div>
          </div>
        </b-container>

      </b-modal>
      <div class="action-btn split-title mt-5">
        <router-link to="" @click.native="navigatePrevious" class="dark-btn">Back</router-link>
        <div class="action-next">
          <router-link to="" @click.native="saveEnrollment" class="button-green">Save & Continue Later</router-link>
          <router-link to="" @click.native="saveData" class="button-brand">Next</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import html2canvas from 'html2canvas';
import {required} from "vuelidate/lib/validators";
import {
  onlyAlphabet,
  checkSsn,
  checkEmail,
  checkValidZip,
  checkSecurityCode,
  accountNumber,
  checkCreditCardNumber,
  alphabetDigitSpace
} from "../../utils/validators";
export default {
  name: "BillingInformation",
  props: {
    navdata: {
      type: Array
    }
  },
  data () {
    return {
      tempId: '',
      selected_type: 'eft',
      formFields: {
        routing_number: {
          value: '',
          required: false,
          read_only: false,
        },
        account_name: {
          value: '',
          required: false,
          read_only: false,
        },
        bank_name: {
          value: '',
          required: false,
          read_only: true,
        },
        account_number: {
          value: '',
          required: false,
          read_only: false,
        },
        account_type: {
          value: '',
          required: false,
          read_only: false,
        },
        account_holder_type: {
          value: '',
          required: false,
          read_only: false,
        },
        first_name_on_cc: {
          value: '',
          required: false,
          read_only: false,
        },
        last_name_on_cc: {
          value: '',
          required: false,
          read_only: false,
        },
        card_number: {
          value: '',
          required: false,
          read_only: false,
        },
        expiration_month: {
          value: '',
          required: false,
          read_only: false,
        },
        expiration_year: {
          value: '',
          required: false,
          read_only: false,
        },
        security_code: {
          value: '',
          required: false,
          read_only: false,
        },
        address1: {
          value: '',
          required: false,
          read_only: false,
        },
        address2: {
          value: '',
          required: false,
          read_only: false,
        },
        city: {
          value: '',
          required: false,
          read_only: false,
        },
        state: {
          value: '',
          required: false,
          read_only: false,
        },
        zip: {
          value: '',
          required: false,
          read_only: false,
        },
        bill_date: {
          value: 28
        }
      },
      paymentType: '',
      showEft: false,
      bankData: [],
      formErrorFlag: false,
      formErrors: [],
      existingBank: false,
      isDisabled: false,
      selected: '',
      savePopup: false,
      email: '',
      saveSuccess: false,
      expirationMonths: [this.padMonth(1),this.padMonth(2),this.padMonth(3),this.padMonth(4),this.padMonth(5),this.padMonth(6),this.padMonth(7),this.padMonth(8),this.padMonth(9),10,11,12],
      expirationYears: [],
      states: [],
      choose_pa: false,
      computedPaymentType: '',
      is_physical_address: 'no',
      cardData: [],
      selected_card: '',
      isDisabled_card: false,
      ShowEft:false,
      isEmailNull: false,
      isEmailValid: false,
      selectedExisitngBank: true,
      isExistingBankInfo: false,
      billingId: null,
      accountTypeOptions: [
        { value: null, text: 'Select Account Type', disabled: true},
        { value: 'checking', text: 'Checking' },
        { value: 'savings', text: 'Savings'}
      ],
      accountHolderTypeOptions: [
        { value: null, text: 'Select Account Holder Type', disabled: true},
        { value: 'individual', text: 'Individual' },
        { value: 'company', text: 'Company'}
      ],
      routingErrorMessage: null,
      billingDateOptions: [],
      merchantInfo: {}
    }
  },
  validations() {
    if (this.selected_type === 'eft') {
      return {
        formFields: {
          account_name: {
            value: {
              required,
              alphabetDigitSpace
            }
          },
          bank_name: {
            value: {
              required
            }
          },
          routing_number: {
            value: {
              required,
              checkSsn
            }
          },
          account_number: {
            value: {
              required,
              accountNumber
            }
          },
          account_type: {
            value: {
              required
            }
          },
          account_holder_type: {
            value: {
              required
            }
          }
        }
      }
    } else if (this.formFields.address2.required) {
      return {
        formFields: {
          first_name_on_cc: {
            value: {
              required,
              onlyAlphabet
            }
          },
          last_name_on_cc: {
            value: {
              required,
              onlyAlphabet
            }
          },
          card_number: {
            value: {
              required,
              checkCreditCardNumber
            }
          },
          security_code: {
            value: {
              required,
              checkSecurityCode
            }
          },
          expiration_month: {
            value: {
              required
            }
          },
          expiration_year: {
            value: {
              required
            }
          },
          address1: {
            value: {
              required
            }
          },
          address2: {
            value: {
              required
            }
          },
          city: {
            value: {
              required
            }
          },
          state: {
            value: {
              required
            }
          },
          zip: {
            value: {
              required,
              checkValidZip
            }
          }
        }
      }
    } else {
      return {
        formFields: {
          first_name_on_cc: {
            value: {
              required,
              onlyAlphabet
            }
          },
          last_name_on_cc: {
            value: {
              required,
              onlyAlphabet
            }
          },
          card_number: {
            value: {
              required,
              checkCreditCardNumber
            }
          },
          security_code: {
            value: {
              required,
              checkSecurityCode
            }
          },
          expiration_month: {
            value: {
              required
            }
          },
          expiration_year: {
            value: {
              required
            }
          },
          address1: {
            value: {
              required
            }
          },
          city: {
            value: {
              required
            }
          },
          state: {
            value: {
              required
            }
          },
          zip: {
            value: {
              required,
              checkValidZip
            }
          }
        }
      }
    }
  },
  mounted() {
    let app = this;
    app.selected_type = 'eft'
    app.tempId = window.localStorage.getItem('tempId');
    app.scrollTop();
    app.fetchInfo();
    app.getPaymentType();
    app.setExpirationYears();
    app.getStates();
    app.getBillingDate();
    app.checkMerchantCarrier();
    // app.fetchExiInfo();
  },
  methods: {
    getBillingDate: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-bill-date')
          .then(function (response) {
            if (response.data.status == 'success') {
              let billingDates = response.data.data
              let temp = []
              billingDates.forEach((data) => {
                 let Obj = {}
                 Obj['text'] = `${data.bill_date}th of month`;
                 Obj['value'] = data.bill_date;
                 temp.push(Obj)
              })
              app.billingDateOptions = temp;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    checkedNewBankDetails: function() {
      // if (val === this.previouslySelected) {
      //   this.selected = false;
      // }
      // this.previouslySelected = this.selected;
 /*     if (val === this.selected) {
        this.selected = false
        this.isDisabled = false;
      } else {
        this.selected = val;
        this.isDisabled = true;
      }*/
      this.selected = false
      this.isDisabled = false;
      this.selectedExisitngBank= false;
    },
    checkedExistingBankDetails: function (val) {
      if (this.billingId === undefined) {
        this.selected = val;
        this.isDisabled = true;
        this.selectedExisitngBank = true;
        this.billingId = val;
      } else {
        this.selected = val;
        this.isDisabled = true;
        this.selectedExisitngBank = true;
      }
    },
    uncheckCard: function(val) {
      if (val === this.selected_card) {
        this.selected_card = false
        this.isDisabled_card = false;
      } else {
        this.selected_card = val;
        this.isDisabled_card = true;
      }
    },
    getPaymentType: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-payment-method', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status === 'success') {
              app.paymentType = response.data.data.result;
              if (app.paymentType === 'elist') {
                app.selected_type = 'elist'
                app.maintainPaymentTypeELIST();
              }
              if (app.paymentType === 'list') {
                app.selected_type = 'list'
                app.maintainPaymentTypeLIST();
              }
              if (app.paymentType === 'stmt') {
                app.selected_type = 'stmt'
                app.maintainPaymentTypeSTMT();
              }
              app.computedPaymentType = response.data.data.result;
              if (app.paymentType === 'cc' && app.selected_type === 'eft') {
                app.paymentType = 'eft';
              }
            }
            if (app.computedPaymentType === 'eft' || app.computedPaymentType === 'cc') {
              app.getFields();
              if (app.paymentType === 'eft') {
                app.fetchExiInfo();
              } else if (app.computedPaymentType === 'cc') {
                app.fetchExistingCardInfo();
              }
            }
            app.maintainPaymentType();
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getFields: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-form-fields-billing-info', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              response.data.data.result.forEach(function(item) {
                app.formFields[item.field_name] = item;
              });
            }
            app.fetchInfo();
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    fetchExiInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-existing-bank-info', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              if (response.data.data.result == null) {
                app.bankData = [];
                app.selected= '';
                app.isDisabled = false;
              }else{
                app.bankData = response.data.data.result;
                app.selected = app.selectExistingBank();
                app.isDisabled = true;
                app.bankData.forEach(function(item) {
                  item.account_number = 'xxxxx'+item.account_number;
                });
              }
            }
          })

          .catch(function (error) {
            if (error.response.data.status == 'error') {
              // app.fetchInfo();
            }
          });
    },
    fetchExistingCardInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-existing-card-info', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              if (response.data.data.result == null) {
                app.cardData = [];
                app.selected_card= '';
                app.isDisabled_card = false;
              }else{
                app.cardData = response.data.data.result;
                app.selected_card = app.cardData[0].card_id;
                app.isDisabled_card = true;
                app.cardData.forEach(function(item) {
                  item.card_number = 'xxxxxx'+item.card_number;
                  item.expiration_month = item.expiration_month + '/' + item.expiration_year;
                });
              }
              // app.fetchInfo();
            }
          })
          .catch(function (error) {
            if (error.response.data.status == 'error') {
              app.fetchInfo();
            }
          });
    },
    fetchInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
        params: {
          enrollment_id: app.tempId,
          column: 'payment_info',
        }
      })
          .then(function (response) {
            let fetchedData = [];
            let if_cc=app.selected_type;
            if (response.data.data.result) {
               if (app.billingId === undefined) {
                 app.selected = false
                 app.isDisabled = false;
                 app.selectedExisitngBank= false;
               } else {
                 app.isExistingBankInfo = true
                 app.billingId = response.data.data.result.bank_id
                 app.selected = response.data.data.result.bank_id
               }
            }
            if (response.data.status == 'success') {
              for(var i in response.data.data.result) {
                fetchedData.push([i, response.data.data.result [i]]);
              }
              fetchedData.forEach(function(item) {
                let key = item[0];
                let val = item[1];
                if (key != 'enrollment_id'){
                  if (key == 'payment_method') {
                    //need to select the respective radio button
                    app.selected_type = val;
                    app.paymentType = val;
                    if (val == 'cc') {
                      // app.selected_card= '';
                      // app.isDisabled_card = false;
                      app.showEft = true;
                      // app.fetchExistingCardInfo();
                    }
                    if(if_cc == 'cc'){
                      app.selected_type = 'cc';
                      app.paymentType = 'cc';
                    }
                  }else if (key == 'is_physical_address') {
                    app.is_physical_address = val;
                    if (app.is_physical_address == 'yes') {
                      app.choose_pa = true;
                    }
                  } else {
                    app.formFields[key]['value'] = val;
                  }
                }
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    validRouting: function () {
      if (this.$v.formFields.routing_number.value.$invalid) {
        return false;
      } else {
        let app = this;
       /* let params = {
          q: app.formFields.routing_number.value,
        };*/
        axios.get(`${process.env.VUE_APP_ROUTING_NUMBER_API}/routing-number-bank-lookup?q=${app.formFields.routing_number.value}`)
            .then(function (response) {
              console.log(response.data.data);
              if (response.data.status == 'success') {
                app.formFields.bank_name.value = response.data.data.response.name.toUpperCase();
                app.formErrorFlag = false;
                app.formErrors['routing_number_message'] = '';
              }
            })
            .catch(function (error) {
              console.log(error.response.data.data);
              if (error.response.data.status == 'error') {
                let item = error.response.data
                console.log('item error', item.message)
                app.formErrorFlag = true;
                app.formFields.bank_name.value = '';
                app.routingErrorMessage = item.message;
              }
            });
      }
    },
    saveData: function () {
      let app = this;
      if (this.paymentType === 'elist' || this.paymentType === 'stmt' || this.paymentType === 'list') {
        let params;
        let url = process.env.VUE_APP_API_BASE + '/save-billing-info';
        params = {
          enrollment_id: app.tempId,
          payment_method: app.paymentType,
          routing_number: app.formFields.routing_number.value,
          account_name: app.formFields.account_name.value,
          bank_name: app.formFields.bank_name.value,
          account_number: app.formFields.account_number.value,
          account_type: app.formFields.account_type.value,
          account_holder_type: app.formFields.account_holder_type.value,
          bill_date: app.formFields.bill_date.value
        };
        app.formErrors = [];
        //  this.saveAndContinue()
        //discard null values
        for (const key of Object.keys(params)) {
          if (params[key] === null || params[key] == "") {
            delete params[key];
          }
        }
        axios.post(url, params)
            .then(function (response) {
              if (response.status == 200) {
                console.log("success");
                app.saveAndContinue();
              }
            })
            .catch(function (error) {
              console.log(error.response.data.data);
              if (error.response.data.status == 'error') {
                error.response.data.data.forEach(function (item) {
                  app.formErrorFlag = true;
                  app.formErrors[item.target_element + '_message'] = item.error_message;
                });
              }
            });
      } else if ((app.isDisabled === true && app.selected_type !== 'cc') || (app.isDisabled_card === true && app.selected_type !== 'eft')){
        // Submit API
        let app = this;
        app.formErrorFlag = false;
        let url = '';
        let params;
        let account_holder_type = '';
        let account_type = '';
        let account_name = '';
        let bank_name = '';
        let bank_routing = '';
        app.formErrors = [];
        url = process.env.VUE_APP_API_BASE + '/save-billing-info-existing';
        if (app.selected_type === 'cc') {
          params = {
            enrollment_id: app.tempId,
            payment_method: 'cc',
            card_id: app.selected_card
          };
        } else {
          app.bankData.forEach(function (item) {
            if (item.bank_id === app.selected) {
              account_holder_type = (item.account_holder_type == null) ? 'individual' : item.account_holder_type;
              account_type = (item.account_type == null) ? 'checking' : item.account_type;
              account_name = (item.account_name ? item.account_name : null);
              bank_name = (item.bank_name ? item.bank_name : null);
              bank_routing = (item.bank_routing ? item.bank_routing : null);
            }
          });
          params = {
            enrollment_id: app.tempId,
            payment_method: app.paymentType,
            bank_id: app.selected,
            account_type: account_type,
            account_holder_type: account_holder_type,
            account_name: account_name,
            bank_name : bank_name,
            bank_routing : bank_routing,
            bill_date: app.formFields.bill_date.value
          };
          //discard null values
          for (const key of Object.keys(params)) {
            if (params[key] === null || params[key] == "") {
              delete params[key];
            }
          }
          axios.post(url, params)
              .then(function (response) {
                if (response.status == 200) {
                  console.log("success");
                  app.saveAndContinue();
                }
              })
              .catch(function (error) {
                console.log(error.response.data.data);
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.formErrorFlag = true;
                    app.formErrors[item.target_element + '_message'] = item.error_message;
                  });
                }
              });
        }
      } else {
        this.$v.$touch();
        if (this.$v.$invalid) {
          console.log('***** Inside Save Data Invalid*****')
          return false
        } else {
          const el = this.$refs.saveBilling;
          (async () => {
            this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'billing_info');
          })();
          // Submit API
          let app = this;
          app.formErrorFlag = false;
          let url = '';
          let params;
          //  let account_holder_type = '';
          //  let account_type = '';
          app.formErrors    = [];
          // From old code version
          /*           if ((app.isDisabled === true && app.selected_type !== 'cc') || (app.isDisabled_card === true && app.selected_type !== 'eft')) {
             url = process.env.VUE_APP_API_BASE + '/save-billing-info-existing';
             if (app.selected_type === 'cc') {
               params = {
                 enrollment_id: app.tempId,
                 payment_method: 'cc',
                 card_id: app.selected_card
               };
             } else {
               app.bankData.forEach(function (item) {
                 if (item.bank_id === app.selected) {
                   account_holder_type = (item.account_holder_type == null) ? 'individual' : item.account_holder_type;
                   account_type = (item.account_type == null) ? 'checking' : item.account_type;
                 }
               });
               params = {
                 enrollment_id: app.tempId,
                 payment_method: app.paymentType,
                 bank_id: app.selected,
                 account_type: account_type,
                 account_holder_type: account_holder_type,
               };
             }
           } else {
             url = process.env.VUE_APP_API_BASE + '/save-billing-info';
             if (app.selected_type == 'cc') {
               params = {
                 enrollment_id: app.tempId,
                 payment_method: 'cc',
                 first_name_on_cc: app.formFields.first_name_on_cc.value,
                 last_name_on_cc: app.formFields.last_name_on_cc.value,
                 card_number: app.formFields.card_number.value,
                 expiration_month: app.formFields.expiration_month.value,
                 expiration_year: app.formFields.expiration_year.value,
                 security_code: app.formFields.security_code.value,
                 address1: app.formFields.address1.value,
                 address2: app.formFields.address2.value,
                 city: app.formFields.city.value,
                 state: app.formFields.state.value,
                 zip: app.formFields.zip.value,
                 is_physical_address: app.is_physical_address
               };
               console.log(params['first_name_on_cc']);
             } else {
               params = {
                 enrollment_id: app.tempId,
                 payment_method: app.paymentType,
                 routing_number: app.formFields.routing_number.value,
                 account_name: app.formFields.account_name.value,
                 bank_name: app.formFields.bank_name.value,
                 account_number: app.formFields.account_number.value,
                 account_type: app.formFields.account_type.value,
                 account_holder_type: app.formFields.account_holder_type.value,
               };
             }
           }*/
          // From old code version
          url = process.env.VUE_APP_API_BASE + '/save-billing-info';
          if (app.selected_type == 'cc') {
            params = {
              enrollment_id: app.tempId,
              payment_method: 'cc',
              first_name_on_cc: app.formFields.first_name_on_cc.value,
              last_name_on_cc: app.formFields.last_name_on_cc.value,
              card_number: app.formFields.card_number.value,
              expiration_month: app.formFields.expiration_month.value,
              expiration_year: app.formFields.expiration_year.value,
              security_code: app.formFields.security_code.value,
              address1: app.formFields.address1.value,
              address2: app.formFields.address2.value,
              city: app.formFields.city.value,
              state: app.formFields.state.value,
              zip: app.formFields.zip.value,
              is_physical_address: app.is_physical_address
            };
            console.log(params['first_name_on_cc']);
          } else {
            params = {
              enrollment_id: app.tempId,
              payment_method: app.paymentType,
              routing_number: app.formFields.routing_number.value,
              account_name: app.formFields.account_name.value,
              bank_name: app.formFields.bank_name.value,
              account_number: app.formFields.account_number.value,
              account_type: app.formFields.account_type.value,
              account_holder_type: app.formFields.account_holder_type.value,
              bill_date: app.formFields.bill_date.value
            };
          }
          //discard null values
          for (const key of Object.keys(params)) {
            if (params[key] === null || params[key] == "") {
              delete params[key];
            }
          }
          axios.post(url, params)
              .then(function (response) {
                if (response.status == 200) {
                  console.log("success");
                  app.saveAndContinue();
                }
              })
              .catch(function (error) {
                console.log(error.response.data.data);
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.formErrorFlag = true;
                    app.formErrors[item.target_element + '_message'] = item.error_message;
                  });
                }
              });
        }
      }
    },
    saveAndContinue() {
      //Update APIs
      //Navigate to next page
      if (localStorage.getItem("navto") === null || localStorage.getItem("navto") === undefined || localStorage.getItem("navto") === '') {
        this.$emit('comEnvNavigation', this.$route.name);
      }else{
        window.localStorage.removeItem('navto');
        this.$router.push('review-confirm');
      }
    },
    saveAndContinueEnrollment: function () {
      if (!this.isEmailValid) {
        this.isEmailNull = true;
      } else {
        console.log('called');
        let app = this;
        app.formErrorFlag = false;
        let params;
        let account_holder_type = '';
        let account_type = '';
        app.formErrors = [];
        if (app.isDisabled == true || app.isDisabled_card == true) {
          if (app.selected_type == 'cc') {
            params = {
              enrollment_id: app.tempId,
              payment_method: 'cc',
              card_id: app.selected_card
            };
          } else {
            app.bankData.forEach(function (item) {
              if (item.bank_id == app.selected) {
                account_holder_type = (item.account_holder_type == null) ? 'individual' : item.account_holder_type;
                account_type = (item.account_type == null) ? 'checking' : item.account_type;
              }
            });
            params = {
              payment_method: app.paymentType,
              bank_id: app.selected,
              account_type: account_type,
              account_holder_type: account_holder_type,
            };
          }
        } else {
          if (app.selected_type == 'cc') {
            params = {
              enrollment_id: app.tempId,
              payment_method: 'cc',
              first_name_on_cc: app.formFields.first_name_on_cc.value,
              last_name_on_cc: app.formFields.last_name_on_cc.value,
              card_number: app.formFields.card_number.value,
              expiration_month: app.formFields.expiration_month.value,
              expiration_year: app.formFields.expiration_year.value,
              security_code: app.formFields.security_code.value,
              address1: app.formFields.address1.value,
              address2: app.formFields.address2.value,
              city: app.formFields.city.value,
              state: app.formFields.state.value,
              zip: app.formFields.zip.value,
              is_physical_address: app.is_physical_address
            };
          } else {
            params = {
              enrollment_id: app.tempId,
              payment_method: app.paymentType,
              routing_number: app.formFields.routing_number.value,
              account_name: app.formFields.account_name.value,
              bank_name: app.formFields.bank_name.value,
              account_number: app.formFields.account_number.value,
              account_type: app.formFields.account_type.value,
              account_holder_type: app.formFields.account_holder_type.value,
            };
          }
        }
        //discard null values
        for (const key of Object.keys(params)) {
          if (params[key] === null || params[key] == "") {
            delete params[key];
          }
        }
        let data = {
          enrollment_id: app.tempId,
          email: app.email,
          tab: 'payment_info',
          data: params
        }
        console.log(data);
        app.formErrorFlag = false;
        app.formErrors = [];
        axios.post(process.env.VUE_APP_API_BASE + '/save-enrollment', data)
            .then(function (response) {
              if (response.data.status == 'success') {
                app.saveSuccess = true;
                console.log("success");
              }
            })
            .catch(function (error) {
              if (error.response.data.status == 'error') {
                console.log('error');
                error.response.data.data.forEach(function (item) {
                  app.formErrorFlag = true;
                  app.formErrors[item.target_element + '_message'] = item.error_message;
                });
                console.log(app.formErrors);
              }
            });
      }
    },
    saveEnrollment : function () {
      let app = this;
      app.$bvModal.show('code-send');
    },
    closePopup : function () {
      let app = this;
      app.savePopup = false;
    },
    navigatePrevious() {
      //Update APIs
      //Navigate to next page
      this.$emit('comEnvNavigationBack', this.$route.name);
    },
    saveScreenShot (image, name) {
      let app = this;
      let data = {
        "enrollment_id" : app.tempId,
        "name" : name,
        "image" : image
      };
      let config = {
        method: 'post',
        url: process.env.VUE_APP_API_BASE+'/save-screenshot',
        headers: { },
        data : data
      };
      axios(config)
          .then(function (response) {
            if (response.data.status == 'success') {
              console.log('screenshot saved');
            }
          })
          .catch(function (error) {
            console.log('screenshot not saved');
            console.log(error);
          });
    },
    scrollTop(){
      window.scrollTo(0,0);
    },
    maintainPaymentTypeEFT: function () {
      let app = this;
      app.selected_type = 'eft';
      if (app.paymentType === 'cc') {
        app.paymentType = 'eft';
      }
    },
    maintainPaymentTypeCC: function () {
      let app = this;
      app.ShowEft = true;
      app.selected_type = 'cc';
      app.showEft = true;
      app.fetchExistingCardInfo();
    },
    setExpirationYears: function () {
      let currentYear = new Date().getFullYear();
      let targetYear = currentYear + 20;
      for (let year = currentYear; year <= targetYear ; year ++) {
        this.expirationYears.push(year);
      }
    },
    getStates: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-states')
          .then(function (response) {
            if (response.data.status == 'success') {
              app.states = response.data.data.result;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    populatePhysicalAddress: function () {
      let app = this;
      if (!app.choose_pa) {
        app.is_physical_address = 'yes';
        axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'contact_info',
          }
        })
            .then(function (response) {
              if (response.data.status == 'success') {
                if (response.data.status == 'success') {
                  app.formFields['address1']['value'] = response.data.data.result.address1;
                  app.formFields['address1']['read_only'] = true;
                  app.formFields['address2']['value'] = response.data.data.result.address2;
                  app.formFields['address2']['read_only'] = true;
                  app.formFields['city']['value'] = response.data.data.result.city;
                  app.formFields['city']['read_only'] = true;
                  app.formFields['state']['value'] = response.data.data.result.state;
                  app.formFields['state']['read_only'] = true;
                  app.formFields['zip']['value'] = response.data.data.result.zip;
                  app.formFields['zip']['read_only'] = true;
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      } else {
        app.is_physical_address = 'no';
        app.formFields['address1']['value'] = null;
        app.formFields['address1']['read_only'] = false;
        app.formFields['address2']['value'] = null;
        app.formFields['address2']['read_only'] = false;
        app.formFields['city']['value'] = null;
        app.formFields['city']['read_only'] = false;
        app.formFields['state']['value'] = null;
        app.formFields['state']['read_only'] = false;
        app.formFields['zip']['value'] = null;
        app.formFields['zip']['read_only'] = false;
      }
    },
    padMonth: function(number) {
      return (number < 10) ? '0' + number.toString() : number.toString();
    },
    maintainPaymentTypeLIST () {
      let app = this;
      app.selected_type = 'list';
      app.paymentType = 'list';
      app.showEft = false;
    },
    maintainPaymentTypeELIST () {
      let app = this;
      app.selected_type = 'elist';
      app.paymentType = 'elist';
      app.showEft = false;
    },
    maintainPaymentTypeSTMT () {
      let app = this;
      app.selected_type = 'stmt';
      app.paymentType = 'stmt';
      app.showEft = false;
    },
    removeError () {
      this.formErrors = []
      this.routingErrorMessage = null
    },
    emailValid () {
      this.isEmailValid = checkEmail(this.email)
      this.isEmailNull = !checkEmail(this.email)
    },
    selectExistingBank () {
      let app = this;
      if (app.isExistingBankInfo) {
        app.bankData.forEach ( data => {
          if (data.bank_id === app.billingId ) {
            app.selected = app.billingId
            return app.selected;
          }
        })
      } else {
        return app.bankData[0].bank_id;
      }
    },
    checkMerchantCarrier () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE_STORE+'/get-billing-text', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
             app.merchantInfo = response.data.data
          })
          .catch(function (error) {
            console.log(error);
          });
    }
  }
}
</script>

<style lang="scss">
.bank-details-radio-align {
  position: relative;
  left: 8px;
  margin-bottom: 20px;
}
.radio-btn-align {
  position: relative;
  top: 25px;
  @media  only screen and (max-width: 765px) {
    top: 0px;
  }
}
.billing-date {
  margin-bottom: 18px;
  div {
    display: inline-flex;
    padding-right: 30px;
  }
  .custom-control-label {
    position: relative;
    margin-left: 5px;
    top: 5px;
  }
}
.bank-existing-list-container {
  position: relative;
  top: -7px;
  left: -22px;
  @media (min-width: 991px) and (max-width: 1199px) {
    position: relative;
    top: -7px;
    left: 0px;
  }
  @media  only screen and (max-width: 765px) {
    position: relative;
    top: 0px;
    left: 0px;
    padding:10px;
  }
  .existing-form-inner-wrapper {
    @media  only screen and (max-width: 765px) {
      padding: 5px 12px;
    }
  }
}
</style>
